import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './LinkButton.module.scss'
import {Link} from "react-router-dom";

interface LinkButtonProps {
    className?: string,
    icon: string,
    title: string,
    active: boolean,
    onClick: (name: string) => void,
    isClose: boolean,
    to: string
}
export const LinkButton = (props: LinkButtonProps) => {
    const { className,
        isClose,
        onClick,
        title,
        active,
        icon,
        to} = props;
    return (
        <Link to={to} className={classNames(cls.LinkButton,
                 {[cls.active]: active, [cls.close]: isClose},
                 [className])}
             onClick={() => onClick(to)}>
            <img src={icon} className={cls.LinkButtonIcon}/>
            <span className={classNames(cls.LinkButtonTitle, {[cls.close]: isClose})}>
                {title}
            </span>
            <div className={cls.Ellipse}/>
        </Link>
    );
};
