import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import React, {Suspense} from "react";
import "./index.scss";
import {StoreProvider} from "@/app/providers/StoreProvider";
import { render } from 'react-dom';
import {App} from "@/app/App";
import {LazyCases} from "@/pages/CasesPage/CasesPage.lazy";
import {LazyCase} from "@/pages/CasePage/CasePage.lazy";
import {ProfileInventory} from "@/pages/ProfilePage/ProfilePage.lazy";
import {LazyContract} from "@/pages/ContractPage/ContractPage.lazy";
import {LazyPayments} from "@/pages/PaymentsPage/PaymentsPage.lazy";
import {LazyFaq} from "@/pages/FaqPage/FaqPage.lazy";
import {TermsPageLazy} from "@/pages/TermsPage/TermsPage.lazy";
import {LazyContacts} from "@/pages/ContactsPage/ContractsPage.lazy";
import {LazyUpgrades} from "@/pages/UpgradesPage/UpgradesPage.lazy";
import {Loading} from "@/shared/ui/Loading/Loading";
import BonusPaymentCasePage from "@/pages/BonusPaymentCasePage/BonusPaymentCasePage";
import {LazySteamAuthPage} from "@/pages/SteamAuthPage/SteamAuthPage.lazy";


const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: '/',
                element:  <Suspense fallback={<Loading/>}><LazyCases /></Suspense>
            },{
                path: '/contracts',
                element: <Suspense fallback={<Loading/>}><LazyContract /></Suspense>
            },{
                path: '/payments',
                element: <Suspense fallback={<Loading/>}><LazyPayments /></Suspense>
            },{
                path: '/faq',
                element: <Suspense fallback={<Loading/>}><LazyFaq /></Suspense>
            },{
                path: '/policy',
                element: <Suspense fallback={<Loading/>}><TermsPageLazy /></Suspense>
            },{
                path: '/contacts',
                element: <Suspense fallback={<Loading/>}><LazyContacts /></Suspense>
            },{
                path: "/upgrades",
                element: <Suspense fallback={<Loading/>}><LazyUpgrades /></Suspense>
            },{
                path: '/case/:id',
                element: <Suspense fallback={<Loading/>}><LazyCase /></Suspense>
            },{
                path: '/bonus_payment_case/:id',
                element: <Suspense fallback={<Loading/>}><BonusPaymentCasePage /></Suspense>
            },{
                path: '/profile/:id',
                element: <Suspense fallback={<Loading/>}><ProfileInventory /></Suspense>
            },{
                path: '/steam_auth',
                element: <Suspense fallback={<Loading/>}><LazySteamAuthPage /></Suspense>
            },{
                path: '*',
                element:  <Navigate to={"/"}/>
            },{
                path: '/',
                element:  <Navigate to={"/"}/>
            }
        ]
    },
]);

render(
    <StoreProvider>
        <RouterProvider router={router} />
    </StoreProvider>,
    document.getElementById('root'),
)
